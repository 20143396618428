.privacy-content {

}

.privacy-content h1 {
    font-size: 1rem;
    color: #1D1D1D;
}

.privacy-content p {
    color: #6D6D6D;
    line-height: 1.4rem
}