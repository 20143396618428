.hero {
    height: 100vh;
    width: 100vw;

    background-image: url("hero-image-medium.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    color: white;
    text-align: center;
}

.hero-content {
    /* is this correct position? I needed relative for the services
    section. I should definitely double check this later...
    TODO
     */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    /* DELETE ME */
    /*background-color: #829CB7;*/
}

.hero-title {
    margin: 0;
    padding: 0;
    border: 0;
    text-transform: uppercase;
    text-shadow: 2px 2px 5px #222222;
}

.hero-paragraph {
    margin: 0;
    padding: 0;
    border: 0;
    text-shadow: 2px 2px 5px #111111;
}

.hero-button {
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 10px;
    color: #000000;
    background-color: #FAD203;
    text-transform: uppercase;

    font-size: 1rem;
    font-weight: bold;

    max-width: 180px;
}

.hero-button:focus {
    outline: none;
}

/* Mobile Small */

.hero-content {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
        "title"
        "paragraph"
        "button";
    align-items: center;
    justify-items: center;

    height: 90%;
    width: 80%;
}

.hero-title {
    grid-area: title;

    font-size: 2.8rem;
    line-height: 1.2;
}

.hero-paragraph {
    grid-area: paragraph;

    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.3;
}

.hero-button {
    grid-area: button;

    height: 2.8rem;
    width: 70%;
}

@media only screen and (min-width: 600px) {

}

@media only screen and (min-width: 768px) {
    .hero-title {
        font-size: 4rem;
    }

    .hero-paragraph {
        font-size: 2rem;
    }

    .hero-button {
        max-width: 250px;
        font-size: 1.2rem;
    }
}