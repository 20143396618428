.footer-wrapper {
    height: auto;
    width: 100%;

    background-color: #1A2D40;
}

.footer-content {
    margin: 0 auto;
    width: 90%;
    max-width: 1350px;

    color: white;

    display: grid;
    grid-template-rows: 125px 100px 55px 50px;
    grid-template-areas:
        "footer-content-logo"
        "footer-content-sections"
        "footer-content-linkedin"
        "footer-content-powered-by";
    align-items: center;
    justify-items: center;
}

.footer-content-logo {
    grid-area: footer-content-logo;
}

.footer-content-sections {
    grid-area: footer-content-sections;
}

.footer-content-linkedin {
    grid-area: footer-content-linkedin;
}

.footer-content-powered-by {
    grid-area: footer-content-powered-by;
}

/*

 */

.footer-content-logo {

}

.footer-content-logo-svg {
    padding-top: 15px;
    height: 85px;
    width: 85px;
}

.footer-content-sections {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: space-evenly;
}

.footer-content-sections li {
    display: inline-block;
    padding: 8px 10px;
}

.footer-content-sections a {
    color: white;
    text-decoration: none;
}

.footer-content-sections a:hover {
    color: #c0df17;
}

.footer-content-sections a:active {
    color: white;
}

.footer-content-sections a:visited {
    color: white;
}

.footer-content-linkedin {

}

.footer-content-linkedin-svg {
    height: 34px;
    width: 34px;
}

.footer-content-powered-by {
    font-size: .8rem;
}