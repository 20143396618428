.navbar-wrapper,
.navbar-wrapper::before,
.navbar-wrapper::after {
    box-sizing: border-box;
}

.navbar-wrapper {
    --navbar-height: 65px;
    --navbar-width: 100%;

    height: var(--navbar-height);
    min-height: var(--navbar-height);
    max-height: var(--navbar-height);

    width: var(--navbar-width);
    min-width: var(--navbar-width);
    max-width: var(--navbar-width);

    z-index: 1000;
    position: sticky;
    top: 0;

    background: rgb(26, 45, 64);
    background: linear-gradient(180deg, rgba(26, 45, 64, 1) 0%, rgba(33, 68, 102, 1) 100%);

    color: white;
}

.navbar-wrapper button {
    margin: 0;
    border: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    background: none;
}

.navbar-wrapper button:focus {
    outline: none;
}

.navbar-wrapper h1 {
    margin: 0;
    border: 0;
    padding: 0;
}

.navbar-small {
    height: var(--navbar-height);
    min-height: var(--navbar-height);
    max-height: var(--navbar-height);

    width: var(--navbar-width);
    min-width: var(--navbar-width);
    max-width: var(--navbar-width);

    display: grid;
    grid-template-columns: .4fr auto .4fr;
    grid-template-areas: "navbar-small-logo navbar-small-text navbar-small-hamburger";

    align-items: center;
    justify-items: center;
}

.navbar-small-logo {
    grid-area: navbar-small-logo;
}

.navbar-small-text {
    grid-area: navbar-small-text;
    text-align: center;
    font-size: 1.1rem;
    font-weight: bold;
}

.navbar-small-hamburger {
    grid-area: navbar-small-hamburger;
    color: lightgrey;
}

.navbar-small-nav {
    display: none;
    width: 100%;
    background: rgba(33, 68, 102, 1);
    text-align: right;
}

.navbar-small-nav-show {
    display: block;
}

nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

nav li {
    margin-bottom: 1em;
    margin-right: 1em;
}

nav a {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    text-transform: uppercase;
}

/* LARGE NAVBAR */

.navbar-large {
    height: var(--navbar-height);
    min-height: var(--navbar-height);
    max-height: var(--navbar-height);

    width: var(--navbar-width);
    max-width: 1350px;

    margin: 0 auto;

    display: grid;
    grid-template-columns: 5% 30% 60% 5%;
    grid-template-areas: ". navbar-large-left navbar-large-nav .";

    align-items: center;
}

.navbar-large-left {
    grid-area: navbar-large-left;
    justify-self: start;

    display: grid;
    grid-template-columns: 30% 70%;
    align-items: center;
    justify-items: center;
}

.navbar-large-logo {
    justify-self: end;
}

.navbar-large-text {
    justify-self: start;
    text-align: center;
}

.navbar-wrapper button {
    height: auto;
    width: auto;
}

.navbar-large-nav {
    grid-area: navbar-large-nav;
    justify-self: end;
}

.navbar-large li {
    display: inline;
}

.navbar-large h1 {
    font-size: 1rem;
}

.navbar-large li a {
    font-size: 1rem;
}

.navbar-large li a:hover {
    color: #c0df17;
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .navbar-large li {
        margin-right: 2.3rem;
    }

}