.leadform-wrapper {
    height: auto;
    min-height: max(650px, 100vh - 65px);
    width: 100%;

    /* TODO */
    /*background-image: url("bonfire-toronto.jpg");*/
    background-image: url("bonfire-toronto-cropped.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    scroll-margin-top: 65px;

}

.leadform-content {
    margin: 0 auto;

    width: 90%;
    max-width: 1350px;

    min-height: max(650px, 100vh - 65px);
    position: relative;

    color: white;
}

.leadform-content-form {
    background: rgba(26, 45, 64, 0.5);
    border-radius: 20px;

    min-width: 100%;
    max-width: 585px;


    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.leadform-content-form-content {
    margin: 0 auto;
    width: 80%;
    min-width: 80%;
    max-width: 80%;

    display: grid;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
        "leadform-content-form-content-header"
        "leadform-content-form-content-text"
        "leadform-content-form-content-form"
        "leadform-content-form-content-privacy";
    grid-row-gap: 1rem;
    align-items: center;

}

.leadform-content-form-content-header h1 {
    display: block;
    margin: 0;
    margin-top: 10px;
    border: 0;
    padding: 0;
}

.leadform-content-form-content-text p {
    display: block;
    margin: 0;
    border: 0;
    padding: 0;
    line-height: 1.4rem;
    margin-bottom: 1.1rem;
}

.leadform-content-form-content-privacy p {
    display: block;
    margin: 0;
    margin-bottom: 10px;
    border: 0;
    padding: 0;

    font-size: .7rem;
}

.leadform-content-form-content-privacy a {
    color: #46B82A;
}

.leadform-content-form-content-privacy a:hover {
    color: #c0df17;
}

.leadform-content-form-content input, textarea {
    display: block;

    margin: 0 auto .8rem;
    height: 2.0rem;
    width: 100%;

    border: 1px solid rgba(26, 45, 64, 0.5);
    border-radius: 10px;

    text-indent: .5rem;
}

.leadform-content-form-content textarea {

    font-family: Arial, serif;

    resize: none;


    height: 5rem;
}

.leadform-content-form-content button {
    display: block;

    float: right;

    /*box-shadow: 0 1px 0 0 grey;*/
    /*background: #33bdef linear-gradient(to bottom, #33bdef 5%, #019ad2 100%);*/
    /*border-radius: 6px;*/
    /*border: 1px solid #057fd0;*/

    cursor: pointer;
    color: #ffffff;
    font-weight: bold;
    padding: 8px 24px;
    text-decoration: none;
}

.pre-submit {
    box-shadow: 0 1px 0 0 grey;
    background: #33bdef linear-gradient(to bottom, #33bdef 5%, #019ad2 100%);
    border-radius: 6px;
    border: 1px solid #057fd0;
}

.valid-submit {
    box-shadow: 0 1px 0 0 #3dc21b;
    background: #44c767 linear-gradient(to bottom, #44c767 5%, #5cbf2a 100%);
    border-radius:6px;
    border:1px solid #18ab29;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

    .leadform-content-form {
        min-width: 500px;
    }

    .leadform-content-form-content textarea {
        height: 6rem;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

    .leadform-content-form {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
    }

    .leadform-content-form-content textarea {
        height: 8rem;
    }

}
