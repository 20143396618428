.testimonial-wrapper {
    height: auto;
    width: 100%;
    background-color: #E5E5E5;
    /* TODO some margin bleedout from the section above? *?
        this should be 65px like the rest.
     */
    scroll-margin-top: 63px;
}

.testimonial-content-wrapper {
    margin: 0 auto;
    width: 90%;
    min-width: 90%;
    max-width: 90%;
    display: grid;
    grid-template-rows: 80px auto auto auto;
    grid-template-areas:
        "testimonial-content-title"
        "testimonial-content-pretext"
        "testimonial-content-objects"
        "testimonial-content-posttext";

    color: white;
}

.testimonial-content-title {
    grid-area: testimonial-content-title;
    align-self: center;
    justify-self: center;
    margin-top: 10px;
    color: #1A2D40;
}

.testimonial-content-title h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    text-align: center;
    color: #1A2D40;
}

.testimonial-content-pretext {
    grid-area: testimonial-content-pretext;

    align-self: center;
    justify-self: center;

    color: #dddddd;
    line-height: 1.4rem;
    text-align: center;
    text-justify: inter-word;
}

.testimonial-content-posttext {
    grid-area: testimonial-content-posttext;

    margin-bottom: 10px;

    align-self: center;
    justify-self: center;

    color: #dddddd;
    line-height: 1.4rem;
    text-align: center;
    text-justify: inter-word;
}

.testimonial-content-objects {
    grid-area: testimonial-content-objects;
    
    margin-bottom: 10px;
    align-self: center;
    justify-self: center;
    color: #dddddd;
    text-align: center;
    text-justify: inter-word;
    border: 2px solid #38618A;
    padding-left: 2%;
    padding-right: 2%;
    
}

/* .carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 200px;
  width: 200px;
  outline: black;
  background-size: 100%, 100%;
  border-radius: 50%;
  border: 1px solid black;
  background-image: none;
} */

.carousel-control-prev-icon{
    background-image: url("../../assets/left_arrow.svg") !important;
    display: inline-block;
    background: transparent no-repeat center center;
    background-size: 100% 100%;
    height: 50px !important;
    width: 50px !important;
    border-image: none !important;
    position: relative;
} 

    
.carousel-control-next-icon
{
    background-image: url("../../assets/right_arrow.svg") !important;
    display: inline-block;
    background: transparent no-repeat center center;
    background-size: 100% 100%;
    height: 50px !important;
    width: 50px !important;
    border-image: none !important;
    position: relative;
}


.carousel .item.left img{
    width: 100% !important;
}

.carousel-indicators li {
    background-color: #000000;
  }

.testimonial-content-text-wrapper {
    box-shadow: 1px 1px 15px 2px #888888;
    margin-bottom: 10px;
    align-self: center;
    justify-self: center;
    color: #6D6D6D;
    background-color: #FFFFFF;
    text-align: center;
    text-justify: inter-word;
    padding: 10px;
    max-width: 700px;
}

.testimonial-content-posttext button {
    float: right;

    background: #33bdef linear-gradient(to bottom, #5B8A5D 5%, #5B8A5D 100%);
    border-radius: 6px;
    border: 1px solid #057fd0;

    cursor: pointer;
    color: #ffffff;
    font-weight: bold;
    padding: 8px 24px;
    text-decoration: none;
}

.testimonial-object p {
    line-height: 1.4rem;
}

.testimonial-content-text-wrapper p {
    margin-left: 4%;
    margin-right: 4%;
    font-size: 13px;
}

.testimonial-content-text-wrapper h3 {
    font-weight: bold;
    color: #1A2D40;
    font-size: 18px;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

    .testimonial-content-pretext {
        max-width: 500px;
    }

    .testimonial-content-posttext {
        max-width: 500px;
    }

    .testimonial-object {
        padding-left: 8px;
        border-left: 2px dashed grey;
        display: grid;
        grid-template-rows: auto 1fr auto;
    }

    .testimonial-object p {
        margin: 0 0 15px;
    }

    .testimonial-ob-0 {

    }

    .testimonial-ob-1 {

    }

    .testimonial-ob-2 {

    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .testimonial-content-pretext {
        max-width: 650px;
    }
    .testimonial-content-posttext {
        max-width: 650px;
    }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    .testimonial-content-text {
        max-width: 750px;
    }
    .testimonial-content-posttext {
        max-width: 750px;
    }
}
