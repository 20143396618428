.about-wrapper {
    height: auto;
    width: 100%;
    background-color: #f2f3f4;
    scroll-margin-top: 65px;
}

.about-content {
    margin: 0 auto;
    width: 90%;
    max-width: 1350px;

    display: grid;
    grid-template-rows: 200px auto;
    grid-template-areas:
        "about-content-logo"
        "about-content-text";
}

.about-content-logo {
    grid-area: about-content-logo;
    justify-self: center;
}

.about-content-logo-svg {
    max-height: 200px;
    width: auto;
}

.about-content-text {
    margin-bottom: 25px;
    grid-area: about-content-text;
    color: #6D6D6D;
}

.about-content-text-header {
    text-align: center;
}

.about-content-text-header > h1 {
    margin: 0;
    border: 0;
    padding: 0;

    font-weight: bold;
    color: #1A2D40;
}

.about-content-text-description {
    margin: 0 auto;
    max-width: 325px;
    line-height: 1.4rem;
    text-align: justify;
    text-justify: inter-word;
}

.about-content-text-description > button {
    float: right;

    box-shadow: 0 1px 0 0 #f0f7fa;
    background: #33bdef linear-gradient(to bottom, #33bdef 5%, #019ad2 100%);
    border-radius: 6px;
    border: 1px solid #057fd0;

    cursor: pointer;
    color: #ffffff;
    font-weight: bold;
    padding: 8px 24px;
    text-decoration: none;

}

.about-content-text-description > button:hover {
    background: #019ad2 linear-gradient(to bottom, #019ad2 5%, #33bdef 100%);
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

    .about-content {

        margin-top: 10px;
        margin-bottom: 10px;

        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
        grid-template-areas:
            "about-content-logo about-content-text";
    }

    .about-content-logo {
        grid-area: about-content-logo;

        place-self: stretch stretch;

        border-right: lightgrey;
        border-right-width: 2px;
        border-right-style: dashed;
    }

    .about-content-logo-svg {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1.7);
    }

    .about-content-text {
        grid-area: about-content-text;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .about-content-logo-svg {
        transform: translate(-50%, -50%) scale(2.2);
    }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    .about-content-logo-svg {
        transform: translate(-50%, -50%) scale(2.5);
    }

    .about-content-text-description {
        max-width: 500px;
    }

}
