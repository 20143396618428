.process-wrapper {
    height: auto;
    width: 100%;
    background-color: #f2f3f4;

    color: #6D6D6D;
    scroll-margin-top: 65px;


}

.process-content {
    margin: 0 auto;
    width: 100%;
    max-width: 1350px;

    /* border-left: lightgrey;
    border-left-width: 2px;
    border-left-style: dashed;

    border-right: lightgrey;
    border-right-width: 2px;
    border-right-style: dashed; */



    display: grid;
    grid-template-rows: auto;
    grid-template-areas:
        "process-content-title"
        "process-items"
        "process-content-posttext";
}

.process-content-title {
    grid-area: process-content-title;

    align-self: center;
    justify-self: center;

    margin-top: 10px;
    margin-bottom: 10px;

}

.process-content-posttext {
    grid-area: process-content-posttext;

    align-self: center;
    justify-self: center;

    margin-top: 10px;
    margin-bottom: 10px;
    align-self: center;
    justify-self: center;

    color: #dddddd;
    line-height: 1.4rem;
    text-align: center;
    text-justify: inter-word;
}

.process-content-title h1 {
    border: 0;
    padding: 0;
    font-size: 20px;

    font-weight: bold;
    color: #1A2D40;
}

.process-content-posttext h1 {
    margin: 0 0 15px;
    border: 0;
    padding-bottom: 10px;
    font-size: 20px;

    font-weight: bold;
    color: #1A2D40;
}

.process-items {
    grid-area: process-items;

    align-self: center;
    justify-self: center;

    width: 100%;

    align-self: center;
    justify-self: center;
    margin: 0 auto;
    
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: space-evenly;
    font-family: Roboto;

    /* background-image: url("process_bg.png"); */
    /* background-image: url('process_bg.png')  no-repeat 0 50%; */
    background: linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url("process_bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

.process-object {
    margin-bottom: 30px;

    width: auto;
    max-width: 300px;

    height: auto;
}

.process-content-object {
    
    margin-bottom: 35px;
    margin-left: 10px;
    margin-right: 10px;

    /* display: flex;
    flex-direction: row;
    flex-wrap: wrap; */

    align-self: center;
    justify-self: center;
    margin: 0 auto;
    max-width: 1080px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: space-evenly;
    font-family: Roboto;
}

.process-card {
    margin: auto;
    display: flex;
    align-items: center;
    padding: 20px;
    margin: 20px;
    width: 200px;
    border: none;
    min-height: 300px;
    max-height: 300px;
}

.process-card p {
    color: #3B3B3B;
    font-family: 'Roboto';
    font-size: 12px;
}

.process-card h5 {
    color: #3B3B3B;
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 16px;
    width:'100%';
    border-bottom:2px solid #000000; 
    margin-top:10px;
}

.process-object button {

    margin-top: 10px;
    float: right;

    box-shadow: 0 1px 0 0 #f0f7fa;
    background: #33bdef linear-gradient(to bottom, #33bdef 5%, #019ad2 100%);
    border-radius: 6px;
    border: 1px solid #057fd0;

    cursor: pointer;
    color: #ffffff;
    font-weight: bold;
    padding: 8px 24px;
    text-decoration: none;
}

.process-object-title {
    border-left: #46B82A;
    border-left-width: 2px;
    border-left-style: solid;
}

.process-object-title h2 {
    margin: 0 0 1.3rem;
    border: 0;
    padding: 0 0 0 .5rem;

    color: #1A2D40;
    font-size: 1.1rem;
}

.process-object-description {
    line-height: 1.4rem;

}

.process-object-description p {
    margin: 0;
    border: 0;
    padding: 0;
}

.process-content-logo {
    display: none;
}

.process-object-0 {
    margin-left: auto;
    margin-right: auto;
}

.process-object-1 {
    margin-left: auto;
    margin-right: auto;
}

.process-object-2 {
    margin-left: auto;
    margin-right: auto;
}

.process-object-3 {
    margin-left: auto;
    margin-right: auto;
}

.process-object-4 {
    margin-left: auto;
    margin-right: auto;
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

    .process-items {
        /* display: grid; */
        grid-template-rows: 2fr 3fr;
        grid-template-areas:
            "process-items-top"
            "process-items-bottom";
    }

    .process-items-top {
        grid-area: process-items-top;

        /* display: grid; */
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "process-items-top-left process-items-top-right";
    }

    .process-items-top-left {
        grid-area: process-items-top-left;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .process-items-top-right {
        grid-area: process-items-top-right;

    }

    .process-items-bottom {
        grid-area: process-items-bottom;

        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "process-items-bottom-left process-items-bottom-right";
    }

    .process-items-bottom-left {
        grid-area: process-items-bottom-left;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .process-content-logo {
        display: block;
        width: 75%;
        height: auto;
    }

    .process-content-logo-svg {
        width: 100%;
        height: auto;
    }

    .process-items-bottom-right {
        grid-area: process-items-bottom-right;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }


    .process-object {
        max-width: 325px;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .process-object {
        max-width: 400px;
    }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    .process-object {
        max-width: 500px;
        margin-bottom: 50px;
    }

}
