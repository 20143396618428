.pricing-wrapper {
    height: auto;
    width: 100%;
    background-color: #1A2D40;
    /* TODO some margin bleedout from the section above? *?
        this should be 65px like the rest.
     */
    scroll-margin-top: 63px;
}

.pricing-content-wrapper {
    margin: 0 auto;
    width: 90%;
    min-width: 90%;
    max-width: 90%;

    display: grid;
    grid-template-rows: 80px auto auto auto;
    grid-template-areas:
        "pricing-content-title"
        "pricing-content-pretext"
        "pricing-content-objects"
        "pricing-content-posttext";

    color: white;
}

.pricing-content-title {
    grid-area: pricing-content-title;

    align-self: center;
    justify-self: center;

    margin-top: 10px;
}

.pricing-content-title h1 {
    margin: 0px;
    border: 0;
    padding: 0;

    font-weight: bold;
    color: white;
    text-align:center
}

.pricing-content-pretext {
    grid-area: pricing-content-pretext;

    align-self: center;
    justify-self: center;

    color: #dddddd;
    line-height: 1.4rem;
    text-align: center;
    text-justify: inter-word;
    margin-top: 15px;
}

.pricing-content-posttext {
    grid-area: pricing-content-posttext;

    margin-bottom: 10px;

    align-self: center;
    justify-self: center;

    color: #dddddd;
    line-height: 1.4rem;
    text-align: center;
    text-justify: inter-word;
}

.pricing-content-posttext span {
    color: #FFFFFF;
}

.pricing-content-posttext h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: #FDE568;
}

.pricing-content-pretext hr {
    width: 131px;
    height: 0px;
    border: 2px solid #FDE568;
    background-color: #FDE568;
}

.pricing-content-objects {
    grid-area: pricing-content-objects;

    align-self: center;
    justify-self: center;
    margin: 0 auto;
    max-width: 1080px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: space-evenly;
    font-family: Roboto;
}

.pricing-content-object {
    max-width: 425px;
    min-width: 250px;
    margin-bottom: 35px;
    margin-left: 10px;
    margin-right: 10px;
}

.pricing-content-object h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 123.8%;
    color: #F2F3F4;
}

.pricing-content-object h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 160%;
    font-family: Roboto;
    letter-spacing: 0.01em;
    font-variant: small-caps;
    color: #F2F3F4;
}

.pricing-content-list {
    font-family: Roboto;
    font-style: normal;
    font-size: 14px;
    line-height: 160%;
    font-family: Roboto;
    color: #F2F3F4;
}

.pricing-card {
    width: 250px;
    padding: none;
    border: none;
}


.pricing-card-body-medium {
    background-Color: '#476C48';
    padding: 0px;
}

.pricing-card-body-large {
    background-Color: '#334D33';
    padding: 0px;
}

.pricing-card-header-small {
    width: 200px;
    height: 90px;
    text-align:center;
    background: #5B8A5D;
    
    min-width: 250px;
}

.pricing-card-header-medium {
    width: 200px;
    height: 90px;
    text-align:center;
    background: #476C48;
    min-width: 250px;
}

.pricing-card-header-large {
    width: 200px;
    height: 90px;
    text-align:center;
    background: #334D33;
    min-width: 250px;
}

.pricing-header-text1 {
    position: absolute;
    width: 112px;
    height: 22px;
    left: calc(50% - 100px/2 + 0.5px);
    top: 23px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 123.8%;
    color: #F2F3F4;
}

.pricing-header-text2 {
    position: absolute;
    width: 80px;
    height: 26px;
    left: calc(50% - 70px/2 + 1px);
    top: 55px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    letter-spacing: 0.01em;
    font-variant: small-caps;
    color: #F2F3F4;
}

.pricing-body-text {
    position: absolute;
    width: 158px;
    height: 23px;
    left: calc(50% - 158px/2);
    top: 128px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    letter-spacing: 0.01em;
    color: #1A2D40;
}

.pricing-content-posttext button {
    float: right;

    background: #33bdef linear-gradient(to bottom, #5B8A5D 5%, #5B8A5D 100%);
    border-radius: 6px;
    border: 1px solid #057fd0;

    cursor: pointer;
    color: #ffffff;
    font-weight: bold;
    padding: 8px 24px;
    text-decoration: none;
}

.pricing-object {
    max-width: 325px;
    margin: 15px 10px;
}

.pricing-object h2 {
    border-left: lightskyblue;
    border-left-width: 2px;
    border-left-style: solid;

    margin: 0 0 1.3rem;
    padding: 0 0 0 .5rem;

    color: #f2f3f4;
    font-size: 1.1rem;
}

.pricing-object p {
    line-height: 1.4rem;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

    .pricing-content-pretext {
        max-width: 500px;
    }

    .pricing-content-posttext {
        max-width: 500px;
    }

    .pricing-object {
        padding-left: 8px;
        border-left: 2px dashed grey;
        display: grid;
        grid-template-rows: auto 1fr auto;
    }

    .pricing-object p {
        margin: 0 0 15px;
    }

    .price-ob-0 {

    }

    .price-ob-1 {

    }

    .price-ob-2 {

    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .pricing-content-pretext {
        max-width: 650px;
    }
    .pricing-content-posttext {
        max-width: 650px;
    }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    .pricing-content-text {
        max-width: 750px;
    }
    .pricing-content-posttext {
        max-width: 750px;
    }
}
