.services-section-wrapper {
    height: auto;
    width: 100%;
    background-color: #1A2D40;
    scroll-margin-top: 65px;
}

.services-content-wrapper {
    margin: 0 auto;
    width: 90%;
    min-width: 90%;
    max-width: 90%;

    display: grid;
    grid-template-rows: 80px auto;
    grid-template-areas:
        "services-title"
        "services-content";
}

.services-content-title {
    grid-area: services-title;

    align-self: center;
    justify-self: center;

    margin-top: 10px;
    margin-bottom: 10px;
}

.services-content-title h1 {
    margin: 0 0 15px;
    border: 0;
    padding: 0;

    font-weight: bold;
    color: white;
}

.services-content-objects {

    margin: 0 auto;
    max-width: 1350px;

    grid-area: services-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: space-evenly;
}

.service-object {
    max-width: 325px;
    margin-bottom: 35px;
    margin-left: 10px;
    margin-right: 10px;
}

.service-object-title {
    border-left: lightskyblue;
    border-left-width: 2px;
    border-left-style: solid;
}

.service-object-title > h2 {
    margin: 0 0 1.3rem;
    padding: 0 0 0 .5rem;

    color: #f2f3f4;
    font-size: 1.1rem;
}

.service-object-description {
    color: #dddddd;

    line-height: 1.5rem;


}

.service-object-description > p {
    margin: 0;
    border: 0;
    padding: 0;
}